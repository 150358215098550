import React from 'react';

const Landing = props => {
    let organizationConfig = props.organizationConfig;
    let updateDefinitionLink = props.updateDefinitionLink
    let apiDetail = props.apiDetail;

    function handleClick() {
        try{
            updateDefinitionLink(apiDetail.apis[0].url)
        } catch (err) {
            alert("Unable to get default API Link");
        }
        
    }

    return (
        <div className="center-box">
            <div className="center-box-text">
                <h1>{apiDetail.name}</h1>
                <p>{apiDetail.description}</p>

            </div>
            <button className="btn default" onClick={() => handleClick()}>Get Started</button>
            <img src="./cis-careerinformationsystem.svg" />
        </div>
    )
}

export default Landing;