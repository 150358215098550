import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SwaggerUI from 'swagger-ui';
import Config from './organization_config.json';
import Sidebar from './Sidebar.js'
import Landing from './Landing.js'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        organizationConfig: null,
        definitionList: null,
        definitionLink: null,
        apiDetail: null
      }
      this.swaggerhub = this.swaggerhub.bind(this)
      this.getOrganizationData = this.getOrganizationData.bind(this)
      this.updateDefinitionLink = this.updateDefinitionLink.bind(this)
    }

  componentWillMount() {
    this.setState({
      organizationConfig:  Config.orgData,      
    })
  }

  componentDidUpdate() {
     if(this.state.definitionLink) {
        SwaggerUI({
            domNode: document.getElementById("api-data"),
            url: this.state.definitionLink
          })
     } else {
        ReactDOM.render(
            <Landing 
                organizationConfig={this.state.organizationConfig} 
                apiDetail={this.state.apiDetail}
                updateDefinitionLink={this.updateDefinitionLink}
            />, document.querySelector('#api-data'));  
     }

  }

  swaggerhub(inputMethod, inputResource, inputParams) {
    let url = "/api-definitions/";    
    return fetch(url, {
        method: "GET"
    }).then(response => {
      if (response.ok) {
        return response.json()
      } throw new Error('There was an issue requesting the API')
    }).then(json => {
      return json
    })
  }

  getOrganizationData(organization) {
    let inputParams = "page=0&limit=10&sort=NAME&order=ASC"
    let inputResource = organization;
    this.swaggerhub('GET', inputResource, inputParams).then(response => {
      this.setState({
        apiDetail: response,
        definitionList: response.apis
      })
    })
  }



  updateDefinitionLink(newLink) {
    this.setState({
      definitionLink: newLink
    })
  }

  render() {
    return (
      <div className="App">
        <Sidebar 
          organizationConfig={this.state.organizationConfig}
          definitionList={this.state.definitionList}
          updateDefinitionLink={this.updateDefinitionLink}
          getOrganizationData={this.getOrganizationData}
        />
        <div id="api-data" />
      </div>
    );
  }
}

export default App;
